import React from "react";
import { useEffect, useState } from 'react';
import axios from 'axios';
// import reactMarkdown from "react-markdown";
import ReactMarkdown from "react-markdown";
import Main_Paragraph from "../components/Main_Paragraph";
import Sub_Paragaph_Img from "../components/Sub_Paragaph_Img";
import Sub_Paragaph from "../components/Sub_Paragraph";
import { Link, useParams } from 'react-router-dom';
import Modal from "../components/Modal";
import LatestBlogs from "../components/LatestBlogs";
import { Helmet } from 'react-helmet';
import { FaArrowLeft } from "react-icons/fa";
import '../assets/css/table.css'

const SingleBlog = () => {

  document.title = "Quantumzyme | Blog";
  const { id } = useParams();
  // const nav_id = id;

  const [Contents, setContents] = useState([]);
  const [Images, setImages] = useState([]);
  const [coverImage, setCoverImage] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [totalNoPosts, setTotalNoPosts] = useState([]);

  useEffect(() => {
    getResponse();
  }, [])

  let getResponse = async () => {
    let response = await axios.get(`https://www.quantumzyme.com/blogs/restapi?_format=json`);
    const res = await axios.get("https://www.quantumzyme.com/blogs/restapi?_format=json");
    let totalNoPosts = res.data.length;

    for (var i = 0; i < res.data.length; i++) {

      if (res.data[i]['field_id'] == id) {
        var loc = i;
      }
    }

    setTotalNoPosts(totalNoPosts);
    let content = response.data[loc];
    console.log('loc', loc)
    console.log('cont', content['field_link3'].split('"')[1])
    await setContents(content);

    // const cover_image = response['data']['data']['attributes']['cover_image']['data']['0']['attributes']['url'];

    let base_url = 'https://www.quantumzyme.com';
    let arr = []
    let img = 'field_image'
    for (let i = 1; i < 7; i++) {
      var spac = img.concat(i)
      console.log(spac)
      // console.log(res.data[loc])
      // response['data']['data']['attributes'][spac]['data']  && arr.push(response['data']['data']['attributes'][spac]['data']['0']['attributes']['url'])
      let url = res.data[loc][spac] && base_url.concat(res.data[loc][spac].split(' ')[4].slice(5, -1))
      arr.push(url)
    }
    await setImages(arr);

    setCoverImage(base_url + response.data[loc]['field_cover_image'].split(' ')[4].slice(5, -1));

  }

  window.scrollTo(0, 700);

  // const imgBackgroundImage = "url(http://15.206.100.212:1337/uploads/Cover_Image_Cofactor_Bound_Enzyme_d8725b98c5.png)";
  // const imgBackgroundImage = "url(http://15.206.100.212:1337" + coverImage + ")";
  const imgBackgroundImage = "url(" + coverImage + ")";
  // let data = Object.keys(Images);
  // console.log(data)
  // let img = JSON.stringify(Contents['Author'])
  // let data = JSON.parse(img)
  // console.log(data)

  // const img = Contents['Image0'];
  // console.log(Object.keys(img))


  const HandleGoBack = () => {
    window.history.back();
  };
  

  return (

    <div>
      <title>{Contents['title']}</title>
      <Helmet>
          <meta property="og:title" content={Contents['title']} />
          <meta property="og:image" content={'https://www.quantumzyme.com' + coverImage} />
          {/* Add more Open Graph tags as needed */}
      </Helmet>


      <div className="position-relative py-6 py-lg-8">
        <div className="bg-holder rounded-soft overlay overlay-0" style={{ backgroundImage: imgBackgroundImage }}></div>
        <div className="position-relative text-center">
          <h4 className="text-white">{Contents['title']}</h4>
        </div>
      </div>

      {/* <!-- <section> begin ============================--> */}
      <section className="bg-100">
        <div className="container">
          <div className="row g-0 position-relative mb-4 mb-lg-0">
            <div className="col-lg-10">
              <section>
                <div className="container">
                  <div className="text-center mb-7">
                    <h3 className="fs-2 fs-md-3">{Contents['title']} <br></br><br></br> {Contents['field_author_name']} | {Contents['field_date']?.slice(16, 26)}</h3>

                    <hr className="short" data-zanim-xs='{"from":{"opacity":0,"width":0},"to":{"opacity":1,"width":"4.20873rem"},"duration":0.8}' data-zanim-trigger="scroll" />
                  </div>

                  <div className="row">

                    <div>
                      <Sub_Paragaph_Img paragraph={Contents?.field_paragraph1} image={Images[0]} link={Contents?.field_link1?.split('"')[1]} />
                      <Sub_Paragaph_Img paragraph={Contents?.field_paragraph2} image={Images[1]} link={Contents?.field_link2?.split('"')[1]} /> 
                      <Sub_Paragaph_Img paragraph={Contents?.field_paragraph3} image={Images[2]} link={Contents?.field_link3?.split('"')[1]} /> 
                      <Sub_Paragaph_Img paragraph={Contents?.field_paragraph4} image={Images[3]} link={Contents?.field_link4?.split('"')[1]} /> 
                      <Sub_Paragaph_Img paragraph={Contents?.field_paragraph5} image={Images[4]} link={Contents?.field_link5?.split('"')[1]} />
                      <Sub_Paragaph_Img paragraph={Contents?.field_paragraph6} image={Images[5]} link={Contents?.field_link6?.split('"')[1]} /> 
                      <Sub_Paragaph paragraph={Contents?.field_references} />
                    </div>

                    {id > 1 ? <a className="col-lg-6 nav-link" href={`/blog/${parseInt(parseInt(id) - parseInt(1))}`} role="button" aria-expanded="false">&#x3c; Previous Blog</a> : <span className="col-lg-6" style={{ fontSize: "0px" }}>nope</span>}
                    {id < totalNoPosts ? <a className="col-lg-6 nav-link" style={{ textAlign: "right" }} href={`/blog/${parseInt(parseInt(id) + parseInt(1))}`} role="button" aria-expanded="false">Next Blog &#x3e;</a> : <span className="col-lg-6" style={{ fontSize: "0px" }}>nope</span>}

                  </div>
                </div>
              </section>
            </div>
            <div className="col-lg-2">
              <section style={{ position: "sticky", top: "0px", width: "100%" }}>
                <div className="container" >

                  <div className="row" >

                    <div className="col-lg-12 px-lg-5 mt-7 mt-lg-0" data-zanim-timeline="{}" data-zanim-trigger="scroll" >
                      <br></br>
                      <div className="overflow-hidden">
                        <div className="px-4 px-sm-0 mt-5" data-zanim-xs='{"delay":0}'>
                          <h5 className="fs-0 fs-lg-1">Latest Blogs</h5>
                          <ul>
                            <LatestBlogs />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="back-button" onClick={HandleGoBack}>
                  <FaArrowLeft />
                </div>

              </section>
            </div>
          </div>
        </div>
      </section>

      {openModal && <Modal setOpenModal={setOpenModal} />}
    </div>
  )
}

export default SingleBlog;