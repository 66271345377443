import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <div>
        <div className="sticky-top navbar-elixir">
        <div className="container">
          {/* <nav className="navbar navbar-expand-lg"> <a className="navbar-brand" href="home.html"><img src="assets/img/logo-dark.png" alt="logo" /></a><button className="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#primaryNavbarCollapse" aria-controls="primaryNavbarCollapse" aria-expanded="false" aria-label="Toggle navigation"><span className="hamburger hamburger--emphatic"><span className="hamburger-box"><span className="hamburger-inner"></span></span></span></button> */}
          <nav className="navbar navbar-expand-lg"> <a className="navbar-brand" href='https://quantumzyme.com'><img src="../assets/img/Quantumzyme_logov2.png" alt="logo"/></a><button className="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#primaryNavbarCollapse" aria-controls="primaryNavbarCollapse" aria-expanded="false" aria-label="Toggle navigation"><span className="hamburger hamburger--emphatic"><span className="hamburger-box"><span className="hamburger-inner"></span></span></span></button>
            <div className="collapse navbar-collapse" id="primaryNavbarCollapse" style={{height:"0px", justifyContent:"right"}}>
              <ul className="navbar-nav py-5 py-lg-0 mt-1 mb-2 my-lg-0 ms-lg-n1">
                {/* <li className="nav-item dropdown"><Link className="nav-link" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">Blog Home</Link> </li> */}
                {/* <li className="nav-item dropdown"><Link className="nav-link" to="/" role="button" aria-expanded="false">Blog Home</Link></li> */}
                {/* <li className="nav-item dropdown"><a className="nav-link" href="/" role="button">Home</a></li> */}
                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle " href="https://quantumzyme.com/about" role="button" data-bs-toggle="dropdown" aria-expanded="false">About Us</a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="https://quantumzyme.com/about#mission_vision">Vision</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/about#qz_team">Leadership</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/about#leaders">Scientific Advisory Board</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/about#qz_journey">Our Journey</a></li>
                  </ul>
                </li>
                <li className="nav-item dropdown"><a className="nav-link" href="https://quantumzyme.com/technology" role="button" data-bs-toggle="dropdown" aria-expanded="false">Technology</a>
                </li>
                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle " href="https://quantumzyme.com/services" role="button" data-bs-toggle="dropdown" aria-expanded="false">Services</a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="https://quantumzyme.com/services.php#enzyme_discovery">Enzyme Discovery</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/services.php#serv_enzyme_engineering">Enzyme Engineering</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/services.php#serv_wetlab_validation">Wet Lab Validation</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/services.php#serv_process_development">Process Development</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/services.php#serv_scaleup">Scale-Up</a></li>
                  </ul>
                </li>
                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle " href="https://quantumzyme.com/research_and_development.php" role="button" data-bs-toggle="dropdown" aria-expanded="false">R & D</a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="https://quantumzyme.com/research_and_development.php#v-pills-profile-tab">R & D Pipeline</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/research_and_development.php#pills-pub-tab">Publications</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/research_and_development.php#pills-case-tab">Case Studies</a></li>
                  </ul>
                </li>
                <li className="nav-item dropdown"><a className="nav-link dropdown-toggle " href="https://quantumzyme.com/media.php" role="button" data-bs-toggle="dropdown" aria-expanded="false">Media</a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="https://quantumzyme.com/media.php#pills-press-releases-tab">In the News</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/media.php#pills-company-statements-tab">Company Statements</a></li>
                    <li><a className="dropdown-item" href="https://quantumzyme.com/media.php#pills-mevents-tab">Events</a></li>
                  </ul>
                </li>
                <li className="nav-item dropdown"><a className="nav-link" href="https://quantumzyme.com/careers.php" role="button">Careers</a></li>


                
                
                {/* <li className="nav-item dropdown"><a className="nav-link" href="contact.html" role="button">About Us</a></li>
                <li className="nav-item dropdown"><a className="nav-link" href="contact.html" role="button">Contact</a></li> */}
                {/* <!-- <div className="container shadow min-vh-100 py-4">
                    <div className="row">
                        <div className="col-md-5 mx-auto">
                            <div className="small fw-light">search input with icon</div>
                            <div className="input-group">
                                <input className="form-control border-end-0 border rounded-pill" type="search" value="search" id="example-search-input">
                                <span className="input-group-append">
                                    <button className="btn btn-outline-secondary bg-white border-bottom-0 border rounded-pill ms-n5" type="button">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div></div> --> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>

      
        </div>
    )
}

export default Navbar;
