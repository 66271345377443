import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { Link } from 'react-router-dom';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

const BlogSection = ({ posts, Loading, bOdd, Img}) => {


  return (
    <div>
        
        {posts.map(post => (

          <>

          {bOdd ? 
          <li key={post.field_id} className='list-group-item'>
          {/* <li key={post.field_id} className='list-group-item' style={{ backgroundColor: `rgb(${dominantColor[0]}, ${dominantColor[1]}, ${dominantColor[2]})` }}> */}
          
          <font style={{fontSize:"0px"}}>
          {Img = post['field_cover_image'].split(' ')[4].slice(5,-1)}
          </font>
          <div className="row g-0 position-relative mb-4 mb-lg-0 team-item">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{alignSelf: "center"}}>
              <div className="team-item-img rounded">
                <img className="img-fluid bg-holder1 center" src={"https://www.quantumzyme.com" + Img} />

              </div> <br></br><br></br>
            </div>
            <div className="col-lg-6 px-lg-5 py-lg-4 p-4 my-lg-0 bg-white rounded-bs-lg rounded-lg-bs-0 rounded-be-lg rounded-lg-be-0 rounded-lg-te-lg ">
              <div className="elixir-caret d-none d-lg-block"></div>
              <div className="d-flex align-items-center h-100">
                <div data-zanim-timeline="{}" data-zanim-trigger="scroll">
                  <div className="overflow-hidden">
                    {/* <h4 data-zanim-xs='{"delay":0}'><a href="single_blog.html"> Blog Title </a></h4> */}
                    {/* <h4 data-zanim-xs='{"delay":0}'><Link to='/blog/'> {post.attributes.Title} </Link></h4> */}
                    <h4 data-zanim-xs='{"delay":0}'><Link to={`/blog/${post.field_id}`}> {post.title} </Link></h4>
                  </div>
                  <div className="overflow-hidden">
                    {/* <p className="mt-3" data-zanim-xs='{"delay":0.1}'>{post.attributes.paragraph1 && post.attributes.paragraph1.slice(0,500)} ...</p> */}
                    <p className="mt-3" data-zanim-xs='{"delay":0.1}'><ReactMarkdown skipHtml={false} rehypePlugins={[rehypeRaw]}>{post.field_paragraph1.slice(0,502)}</ReactMarkdown></p>
                  </div>
                  <div className="overflow-hidden">
                    <p className="mt-3" data-zanim-xs='{"delay":0.1}'>
                      <h5> {post.field_author_name} | {post.field_date.slice(16,26)} </h5>
                    </p>
                  </div>
                  <div className="overflow-hidden">
                    <div data-zanim-xs='{"delay":0.2}'><Link to={`/blog/${post.field_id}`}>Read More<div className="overflow-hidden ms-2"><span className="d-inline-block" data-zanim-xs='{"from":{"opacity":0,"x":-30},"to":{"opacity":1,"x":0},"delay":0.8}'></span></div></Link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
          
          : 
          
          <li key={post.id} className='list-group-item'>
            {/* {post.attributes.Author} */}
            <font style={{fontSize:"0px"}}>
            {Img = post['field_cover_image'].split(' ')[4].slice(5,-1)}
            </font>
            {/* {Img = post.attributes.Image_0.data }  */}
            <div className="row g-0 position-relative mb-4 mb-lg-0 team-item">
              
            <div className="col-lg-6 px-lg-5 py-lg-4 p-4 my-lg-0 bg-white rounded-bs-lg rounded-lg-bs-0 rounded-be-lg rounded-lg-be-0 rounded-lg-te-lg ">
                <div className="elixir-caret d-none d-lg-block"></div>
                <div className="d-flex align-items-center h-100">
                  <div data-zanim-timeline="{}" data-zanim-trigger="scroll">
                    <div className="overflow-hidden">
                      {/* <h4 data-zanim-xs='{"delay":0}'><a href="single_blog.html"> Blog title </a></h4> */}
                      {/* <h4 data-zanim-xs='{"delay":0}'><Link to='/blog/'> {post.attributes.title} </Link></h4> */}
                      <h4 data-zanim-xs='{"delay":0}'><Link to={`/blog/${post.field_id}`}> {post.title} </Link></h4>
                    </div>
                    <div className="overflow-hidden"> 
                    <p className="mt-3" data-zanim-xs='{"delay":0.1}'><ReactMarkdown skipHtml={false} rehypePlugins={[rehypeRaw]}>{post.field_paragraph1.slice(0,549)}</ReactMarkdown></p>
                    </div>
                    <div className="overflow-hidden">
                      <p className="mt-3" data-zanim-xs='{"delay":0.1}'>
                        <h5> {post.field_author_name} | {post.field_date.slice(16,26)} </h5>
                      </p>
                    </div>
                    <div className="overflow-hidden">
                      <div data-zanim-xs='{"delay":0.2}'><Link to={`/blog/${post.field_id}`}>Read More<div className="overflow-hidden ms-2"><span className="d-inline-block" data-zanim-xs='{"from":{"opacity":0,"x":-30},"to":{"opacity":1,"x":0},"delay":0.8}'></span></div></Link></div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s" style={{alignSelf: "center"}}>
                <div className="team-item-img rounded">
                  <img className="img-fluid bg-holder1 center " src={"https://www.quantumzyme.com" + Img} />
                  <div className="text-center p-4">
                  </div>
                </div>
               <br></br><br></br>
              </div>
            </div>
          </li>
          }
          
          {bOdd = !bOdd}
          
          </>
                )) }


        
    </div>
  )
}

export default BlogSection