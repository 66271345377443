import React from 'react'
// import '../assets/css/modal.css';

const Modal = ({setOpenModal}) => {
  return (
    <div className='modal-bg'>
    <div className='modal-container'>
        <div className='title'>
            Title 
        </div>
        <div className='body'>
            Body
        </div>
        <button onClick={() => {setOpenModal(false);}}>Close</button>
    </div>
    </div>
  )
}

export default Modal