import React from 'react';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';

const Sub_Paragaph = ({ paragraph }) => {
  return (
    <div>
        <div className="overflow-hidden">
            <div className="px-4 px-sm-0" data-zanim-xs='{"delay":0}'>
                {/* <h5 className="fs-0 fs-lg-1"><span className="fas fa-palette fs-1 me-2" data-fa-transform="shrink-1"></span>Paragraph2</h5> */}
                {/* <p className="mt-3">{paragraph}</p> */}
                <p><ReactMarkdown skipHtml={false} rehypePlugins={[rehypeRaw]}>{paragraph}</ReactMarkdown></p>
            </div>
        </div>        
    </div>
  )
}

export default Sub_Paragaph;