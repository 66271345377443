import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SingleBlog from './pages/SingleBlog';
import TestPage from './pages/TestPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';


function App() {
  return (
    <Router>
    <div className="App">

    <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
    <link href="../assets/css/theme.min.css" rel="stylesheet" />
    <link href="../vendors/swiper/swiper-bundle.min.css" rel="stylesheet" />
    <link href="../vendors/hamburgers/hamburgers.min.css" rel="stylesheet" />
    <link href="../vendors/loaders.css/loaders.min.css" rel="stylesheet" />
    <link href="../assets/css/theme.min.css" rel="stylesheet" />
    <link href="../assets/css/user.min.css" rel="stylesheet" />
    <link rel="preconnect" href="https://fonts.googleapis.com/" />
    <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin=""/>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&amp;family=Open+Sans:wght@300;400;600;700;800&amp;display=swap" rel="stylesheet"></link>

    <script src="../vendors/popper/popper.min.js"></script>
    <script src="../vendors/bootstrap/bootstrap.min.js"></script>
    <script src="../vendors/is/is.min.js"></script>
    <script src="../vendors/bigpicture/BigPicture.js"> </script>
    <script src="../vendors/countup/countUp.umd.js"> </script>
    <script src="../vendors/swiper/swiper-bundle.min.js"></script>
    <script src="../vendors/fontawesome/all.min.js"></script>
    <script src="../vendors/lodash/lodash.min.js"></script>
    <script src="../vendors/imagesloaded/imagesloaded.pkgd.min.js"></script>
    <script src="../vendors/gsap/gsap.js"></script>
    <script src="../vendors/gsap/customEase.js"></script>
    <script src="../assets/js/theme.js"></script>


    <link href="../assets/css/style_I.css" rel="stylesheet" />
    <link href="../assets/css/style.css" rel="stylesheet" />


        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Poppins:wght@600;700&display=swap"
          rel="stylesheet"
        />
    
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
          rel="stylesheet"
        />


        <link href="../assets/lib_I/animate/animate.min.css" rel="stylesheet" />
        <link href="../assets/lib_I/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
    
    
        <link href="../assets/css/style_I.css" rel="stylesheet" />
        <link href="../assets/css/style.css" rel="stylesheet" />

        <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>

      <Navbar />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/blog/:id' element={<SingleBlog />} />
      </Routes>
      <Footer />
      </div>
    </Router>    
  );
}

export default App;
