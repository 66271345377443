import React from "react";

const Footer = () => {
    return (
        <div>
    <div style={{backgroundColor: "#fff"}}>
      <div className="container-fluid m-0">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className=" text-dark p-4 p-lg-5 rounded-3">
              <center>
            <div className="col-sm-3 text-center"><a href="/"><img src="assets/img/Quantumzyme_logo.png" alt="logo" /></a></div>
              {/* <h4 className="text-dark fs-1 fs-lg-2 mb-1">Sign up for email alerts</h4> */}
              <p className="text-dark col-sm-12 text-center pt-2" >Catalyze Your Business</p>
              {/* <form className="mt-4">
                <div className="row align-items-center">
                  <div className="col-md-7 pe-md-0">
                    <div className="input-group"><input className="form-control" type="email" placeholder="Enter Email Here" /></div>
                  </div>
                  <div className="col-md-5 mt-3 mt-md-0">
                    <div className="d-grid"><button className="btn btn-warning" type="submit"><span className="text-primary fw-semi-bold">Submit</span></button></div>
                  </div>
                </div>
              </form> */}
              </center>
            </div>
          </div>
          <div className="col-lg-8 mt-4 mt-lg-0">
            <div className="row">
              <div className="col-sm-4 col-4 col-md-4 col-lg-4 text-dark ms-lg-auto pt-4">
                <ul className="list-unstyled text-dark">

                  <li className="mb-3"><a className="text-dark" href="https://www.quantumzyme.com/partners">Partners</a></li>
                  <li className="mb-3"><a className="text-dark" href="https://www.quantumzyme.com/customers">Customers</a></li>
                  <li className="mb-3"><a className="text-dark" href="https://www.quantumzyme.com/media#pills-mevents-tab">Events</a></li>
                </ul>
              </div>
              <div className="col-sm-4 col-4 col-md-4 col-lg-4 text-dark ms-lg-auto pt-4">
                <ul className="list-unstyled text-dark">
                  <li className="mb-3"><a className="text-dark" href="https://www.quantumzyme.com/about">About Us</a></li>
                  <li className="mb-3"><a className="text-dark" href="https://www.quantumzyme.com/contact">Contact Us</a></li>
                  <li className="mb-3"><a className="text-dark" href="https://www.quantumzyme.com/research_and_development#pills-pub-tab">Publications</a></li>
                </ul>
              </div>
              {/* <div className="col-4 col-lg-4 ms-sm-auto">
                <ul className="list-unstyled text-dark">
                  <li className="mb-3"><a className="text-decoration-none d-flex align-items-center" href="https://www.linkedin.com/company/quantumzyme/mycompany/" target="_blank" rel="noopener noreferrer"> <span className="brand-icon me-3"><span className="fab fa-linkedin-in"></span></span>
                      <h5 className="fs-0 text-dark mb-0 d-inline-block">Linkedin</h5>
                    </a></li>
                  <li className="mb-3"><a className="text-decoration-none d-flex align-items-center" href="https://twitter.com/quantumzyme" target="_blank" rel="noopener noreferrer"> <span className="brand-icon me-3"><span className="fab fa-twitter"></span></span>
                      <h5 className="fs-0 text-dark mb-0 d-inline-block">Twitter</h5>
                    </a></li>
                  <li className="mb-3"><a className="text-decoration-none d-flex align-items-center" href="https://www.facebook.com/quantumzyme/" target="_blank" rel="noopener noreferrer"> <span className="brand-icon me-3"><span className="fab fa-facebook-f"></span></span>
                      <h5 className="fs-0 text-dark mb-0 d-inline-block">Facebook</h5>
                    </a></li>
                  <li className="mb-3"><a className="text-decoration-none d-flex align-items-center" href="https://www.instagram.com/quantumzyme/?hl=en" target="_blank" rel="noopener noreferrer"> <span className="brand-icon me-3"><span className="fab fa-google-plus-g"></span></span>
                      <h5 className="fs-0 text-dark mb-0 d-inline-block">Instagram</h5>
                    </a></li>
                </ul>
              </div> */}
               <div class="col-xs-12 col-sm-4 col-4 col-md-4 align-self-end mb-4 footer_social mt-3 p-0">
                <a target="_blank" href="https://www.linkedin.com/company/quantumzyme"> <span className="brand-icon me-3"><span className="fab fa-linkedin-in"></span></span></a>
                <a target="_blank" href="https://twitter.com/quantumzyme"><span className="brand-icon me-3"><span className="fab fa-twitter"></span></span></a>
                <a target="_blank" href="https://www.facebook.com/quantumzyme"><span className="brand-icon me-3"><span className="fab fa-facebook-f"></span></span></a>
                <a target="_blank" href="https://www.instagram.com/quantumzyme"><span className="brand-icon me-3"><span className="fab fa-instagram"></span></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer className="footer text-center py-4" style={{backgroundColor: "#fff"}} >
      <div className="container">
        <div className="row align-items-center opacity-85 text-dark">
          {/* <div className="col-sm-3 text-sm-start"><a href="index-2.html"><img src="assets/img/Quantumzyme_logo.png" alt="logo" /></a></div> */}
          <center>
          <div className="col-sm-6 mt-3 mt-sm-0">
            <p className="lh-lg mb-0 fw-semi-bold" style={{textAlign:"center"}}> Copyrights © 2023 All Rights Reserved by Quantumzyme LLP</p>
          </div>
          </center>
          {/* <div className="col text-sm-end mt-3 mt-sm-0"><span className="fw-semi-bold">Designed by </span><a className="text-dark" href="https://themewagon.com/" target="_blank">Themewagon</a></div> */}
        </div>
      </div>
    </footer>
        </div>
    )
}

export default Footer;