import React, { useState } from 'react'
// import reactMarkdown from 'react-markdown';
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import ModalImage from './ModalImage';

// import '../assets/css/style.css';
const Sub_Paragaph_Img = ({ paragraph, image, link }) => {

  const [openImage, setOpenImage] = useState(false);

  const input = '<h1>this is input</h1> \n\n ntex line';
  return (
    <div>
        <div className="overflow-hidden">
            <div className="px-4 px-sm-0" data-zanim-xs='{"delay":0}'>
                {/* <h5 className="fs-0 fs-lg-1"><span className="fas fa-palette fs-1 me-2" data-fa-transform="shrink-1"></span>Paragraph2</h5> */}
                {/* <p className="mt-3">{paragraph}</p>
                <h5>this is markdown</h5>
                  <ReactMarkdown source={input} /> */}
                  <p><ReactMarkdown skipHtml={false} rehypePlugins={[rehypeRaw]}>{paragraph}</ReactMarkdown></p>
                {image && <img className="rounded-3 img-fluid center img-click" onClick={() => {setOpenImage(true)}} src={image} alt="about" />}
                {link && <iframe className="video-play"  src={link} frameborder='0' allow='autoplay; encrypted-media' allowfullscreen title='video' />}
                {openImage && <ModalImage img_src={image} setOpenImage={setOpenImage}/> }
            </div>
        </div>        
    </div>
  )
}

export default Sub_Paragaph_Img;