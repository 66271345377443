import React from 'react'
// import '../assets/css/modal.css';


const ModalImage = ({img_src, setOpenImage}) => {
    return (
        <div className='modal-img-bg'>
        <div className='modal-img-container'>
            <div className='title' style={{textAlignLast: "right"}}>
                {/* <button onClick={() => {setOpenImage(false);}}> X </button> */}
                <span onClick={() => {setOpenImage(false);}} class="close-btn">&times;</span>
            </div>
            <div className='body'>
                {/* Body */}
                <img className="rounded-3 img-fluid center" style={{width:"100%"}}  src={img_src} alt="about" />
            </div>
            {/* <button onClick={() => {setOpenImage(false);}}>Close</button> */}
        </div>
        </div>
      )
}

export default ModalImage