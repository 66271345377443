import React, { useState, useEffect } from "react";
import axios from "axios";
import BlogSection from "../components/BlogSection";
import Select from 'react-select';
import { FaArrowUp } from "react-icons/fa";

const Homepage = () => {
  const [posts, setPosts] = useState([]);
  const [images, setImages] = useState([]);
  const [titles, SetTitles] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [authors, SetAuthors] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [postsPerPage, setPostsPerPage] = useState(4);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [selectedYear, setSelectedYear] = useState("");
  const [yearOptions, setYearOptions] = useState([]);
  const [isSticky, setIsSticky] = useState(false);

  const [showScrollButton, setShowScrollButton] = useState(false);


  useEffect(() => {
    getRes();
    addScrollListener();
    return () => removeScrollListener();
  }, []);

  const getRes = async () => {
    setLoading(true);
    const res = await axios.get("https://www.quantumzyme.com/blogs/restapi?_format=json");
    const sortedPosts = res.data.sort((a, b) => b.field_id - a.field_id);
    // const sortedPosts = res.data.sort((a, b) => a.field_id - b.field_id);
    console.log("Sorted field_IDs:", sortedPosts.map(post => post.field_id));
    setPosts(sortedPosts);
    setFilteredPosts(sortedPosts);
    const years = [...new Set(res.data.map(post => post.field_date.slice(16,20)))];
    const options = [{ value: "", label: "All" }, ...years.map(year => ({ value: year, label: year }))];

    setYearOptions(options);
    const totalNoPosts = filteredPosts.length;

    const arr = [];
    const base_url = 'https://www.quantumzyme.com';
    let url = '';

    for (let i = 0; i < totalNoPosts; i++) {
      url = base_url.concat(res.data[i]['field_cover_image'].split(' ')[4].slice(5,-1))
      arr.push(url);
    }
    setImages(arr);

    const titleArr = [];
    for (let i = 0; i < totalNoPosts; i++) {
      titleArr.push(res.data[i]['Title']);
    }
    SetTitles(titleArr);

    const authorArr = [];
    for (let i = 0; i < totalNoPosts; i++) {
      authorArr.push(res.data[i]['Author']);
    }
    SetAuthors(authorArr);
    setLoading(false);
  };

  const addScrollListener = () => {
    window.addEventListener('scroll', handleScroll);
  };

  const removeScrollListener = () => {
    window.removeEventListener('scroll', handleScroll);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    const currentPosition = scrollTop + clientHeight;
    const atBottom = currentPosition >= scrollHeight - 200;

    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    setShowScrollButton(scrollPosition > 300);

    if (atBottom) {
      setPostsPerPage((prev) => prev + 4);
    }
    setScrollPosition(currentPosition);

    const Position = window.scrollY;
    const threshold = 180; // Adjust this value as needed

    if (Position > threshold) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  
  const handleYearChange = (selectedOption) => {
    const year = selectedOption ? selectedOption.value : "";
    setSelectedYear(year);
    filterPosts(year);
  };

  const filterPosts = (year) => {
    if (year) {
      const filtered = posts.filter(post => post.field_date.slice(16,20) === year);
      setFilteredPosts(filtered);
      setPostsPerPage(4);
    } else {
      setFilteredPosts(posts);
      setPostsPerPage(4);
    }
  };

  const currentPosts = filteredPosts.slice(0, postsPerPage);

  const bOdd = true;
  const Img = "";
  


  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  return (
    <div>
      <section className="bg-100">
        <div className="container">
          <div className="row filter-section" style={{display: 'flex', justifyContent:'flex-end'}}>
            <div className="col-lg-4 col-md-5">
              <label htmlFor="year" className="h5 mt-3"> Filter by Year:</label>
              <Select
                className={`${isSticky ? 'sticky' : ''}`}
                id="year"
                value={yearOptions.find(option => option.value === selectedYear)}
                options={yearOptions}
                onChange={handleYearChange}
              />
            </div>
          </div>
          <BlogSection posts={currentPosts} Loading={isLoading} bOdd={bOdd} Img={Img} />
          {showScrollButton && (
            <div className="scroll-button" onClick={handleScrollToTop}>
              <FaArrowUp />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Homepage;