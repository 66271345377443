import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const LatestBlogs = () => {

    const [blogs, setBlogs] = useState([]);

    useEffect (() => {
        getBlogs();
    },[])

    let getBlogs = async () => {
        let response = await axios.get('http://www.quantumzyme.com/blogs/restapi?_format=json')

        console.log('LB',response.data.slice(0,5))
        let latest_blogs = response.data.slice(0,5);
        setBlogs(latest_blogs);
                
    }

  return (
    <div>
      {blogs.map(blog => (
        <li><a href={`/blog/${blog.field_id}`}>{blog.title}</a> <br></br> <br></br></li>
      ))}
    </div>
  )
}

export default LatestBlogs